import React from 'react';
import IconPropList from '../partials/_RN-PropList';
import IconNotes from '../partials/_RN-Notes';
import {
  CodeSnippet,
  IconDataTableCode,
  PlatformTab,
  Section,
} from '../../../../components';

const snippet = require('raw-loader!../../../../data/snippets/rn-icons-technology.example');

const ReactNativeTab = ({ data }) => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="icon"
        layout="rn"
      />

      <IconPropList />

      <IconNotes />

      <Section title="Icons">
        <IconDataTableCode data={data} platform="reactnative" />
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
